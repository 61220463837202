<script>
import Home from './views/Home.vue'
import Download from './views/Download.vue'
import Doc from './views/docs/Doc.vue'
import Faq from './views/Faq.vue'
import Test from './views/Test.vue'
import Contact from './views/Contact.vue'
import NotFound from './views/NotFound.vue'
import Windows from './views/docs/Windows.vue'
import Linux from './views/docs/Linux.vue'
import Mac from './views/docs/Mac.vue'
import Condition from './views/docs/Condition.vue'
import MachineManage from './views/docs/MachineManage.vue'
import JobManage from './views/docs/JobManage.vue'
import RunInfo from './views/docs/RunInfo.vue'
import BatchManage from './views/docs/BatchManage.vue'
import GetOutput from './views/docs/GetOutput.vue'
import CompositePage from './views/docs/CompositePage.vue'
import Config from './views/docs/Config.vue'
import CyclesDevicePage from './views/docs/CyclesDevicePage.vue'
import FarmSkill from './views/docs/FarmSkill.vue'

const routes = {
  '/': Home,
  '/download': Download,
  '/docs/introduce': Doc,
  '/docs/windows':Windows,
  '/docs/linux':Linux,
  '/docs/macos':Mac,
  '/docs/condition':Condition,
  '/docs/machine_manage':MachineManage,
  '/docs/job_manage':JobManage,
  '/docs/run_info':RunInfo,
  '/docs/batch_manage':BatchManage,
  '/docs/output':GetOutput,
  '/docs/composite':CompositePage,
  '/docs/config':Config,
  '/docs/cycles_device':CyclesDevicePage,
  '/docs/farm_skill':FarmSkill,
  '/faq': Faq,
  '/test': Test,
  '/contact': Contact
}

export default {
  data() {
    return {
      currentPath: window.location.hash,
      lastPath: window.location.hash
    }
  },
  computed: {
    currentView() {
      //this.$forceUpdate()
      //window.location.reload();
      console.error("App computed") 
      console.error(this.currentPath)
      console.error(this.lastPath)
      if (this.lastPath != this.currentPath) {
         this.lastPath = this.currentPath;
         console.error(this.lastPath)
         window.location.reload();
         return
      }
      //this.currentView = routes[this.currentPath.slice(1) || '/'] || NotFound
      //console.error(this.currentPath)
      //console.error(this.currentView)
      return routes[this.currentPath.slice(1) || '/'] || NotFound
    }
  },
 
  mounted() {
    console.error("App mounted")
    window.addEventListener('hashchange', () => {
		  this.currentPath = window.location.hash
		})
  }
}
</script>

<template>
  <h1 >
          <a>&emsp;&emsp;&emsp;&emsp;&emsp;</a><img alt="Vue logo" src="./assets/img/cooltek.png" width="40" height="40"> &emsp; &emsp;  &emsp; &emsp; &emsp;                 
  <a href="#/">首页&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</a> 
  <a href="#/download">下载&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</a> 
  <a href="#/docs/introduce">文档&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</a> 
  <a href="#/faq">常见问题&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</a> 

  <a href="#/contact">联系我们</a>
  </h1>
  <component :is="currentView" />
</template>
<style>
a:hover {
        text-decoration: none;
}

a {
font-size:20px;
text-decoration: none;
}
</style>
