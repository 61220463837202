<template>
                <p><strong>Cycles计算设备自定义配置</strong></p>
                <p>目前BlendStation支持多种Cycles计算设备，包括Nvdia的CUDA和Optix，AMD的HIP，intel的oneAPI，BlendStation默认的是AUTO，也就是自动选择，由BlendStation根据硬件情况自动选择，也支持用户根据具体作业情况选择更适合渲染作业的Cycle计算设备进行渲染<br><br>

                由于现实中，很多艺术工作者的集群中的worker节点性能配置并不是一致的，最典型的场景是4090机器和3060机器在一起渲染，由于某些工程文件对于内存要求比较高，从而导致3060显存溢出失败，这种情况下，有些聪明的艺术家会选择CUDA这种方式进行渲染，因为CUDA可以利用主机内存，这样避免了显存不够导致3060等显存不足的机器无法参与渲染的资源浪费情况
                </p>
                <ol>
                    <p>如何进行Cycles计算设备配置<br>
                        配置文件位于与bin同一级的data目录下的worker.conf即data/worker.conf<br>
		        配置内容如下<br>
                     </p>
                    <code>
{<br>
    "masterAddress": "127.0.0.1",<br>
    "masterPort": 22200,<br>
    "workerPort": 22201,<br>
    "filePort": 2080,<br>
    "cyclesDevice":"AUTO",<br>您可以根据作业情况，将AUTO改为CUDA,OPTIX,ONEAPI,HIP中的任何一个
    "workerConfigPath": "../data/worker.conf"<br>
}                     

                        </code>
                       <p>修改后重新启动对应的应用，也就是启动BlenddStation-Worker</p>
                       <p>需要注意的是用户需要确保修改的配置符合该机器上的显卡厂商以及显卡确实支持该特性，否则会造成性能浪费 </p>
                </ol>
</template>
